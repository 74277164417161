// extracted by mini-css-extract-plugin
export var flexColumn = "contactForm-module--flexColumn--17Y6I";
export var flexColumnStart = "contactForm-module--flexColumnStart--2pQPF";
export var flexColumnAlignStart = "contactForm-module--flexColumnAlignStart--OSHGU";
export var flexColumnAlignCenter = "contactForm-module--flexColumnAlignCenter--uy22i";
export var flexColumnCenter = "contactForm-module--flexColumnCenter--2SZi6";
export var flexRow = "contactForm-module--flexRow--13iHC";
export var flexRowStart = "contactForm-module--flexRowStart--2PMn4";
export var flexRowAlignStart = "contactForm-module--flexRowAlignStart--2jtiS";
export var flexRowLeft = "contactForm-module--flexRowLeft--2Kxr8";
export var flexRowSpaceBetween = "contactForm-module--flexRowSpaceBetween--1WkFA";
export var flexRowAlignCenter = "contactForm-module--flexRowAlignCenter--1XoHM";
export var flexRowCenter = "contactForm-module--flexRowCenter--1tPEA";
export var submit = "contactForm-module--submit--wY64m";
export var flexRowNowrap = "contactForm-module--flexRowNowrap--1GlMj";
export var contributor = "contactForm-module--contributor--1pBeU";
export var blogListItem = "contactForm-module--blogListItem--3jzcv";
export var blogDetailWrapper = "contactForm-module--blogDetailWrapper--3SzgU";
export var blogListWrapper = "contactForm-module--blogListWrapper--3zZLM";
export var margin1 = "contactForm-module--margin1--12fAw";
export var margin1_side = "contactForm-module--margin1_side--14h_0";
export var margin1_vertical = "contactForm-module--margin1_vertical--UMKPu";
export var margin1_top = "contactForm-module--margin1_top--f6cnv";
export var margin1_bottom = "contactForm-module--margin1_bottom--yVLYd";
export var margin1_left = "contactForm-module--margin1_left--28Csx";
export var margin1_right = "contactForm-module--margin1_right--1nHR5";
export var margin15 = "contactForm-module--margin1-5--1YWji";
export var margin15_side = "contactForm-module--margin1-5_side--Rz8Cl";
export var margin15_vertical = "contactForm-module--margin1-5_vertical--2eAM4";
export var margin15_top = "contactForm-module--margin1-5_top--5744s";
export var margin15_bottom = "contactForm-module--margin1-5_bottom--2M8x6";
export var margin15_left = "contactForm-module--margin1-5_left--1M9Zk";
export var margin15_right = "contactForm-module--margin1-5_right--1PdJM";
export var margin2 = "contactForm-module--margin2--UzNNP";
export var margin2_side = "contactForm-module--margin2_side--3qgcw";
export var margin2_vertical = "contactForm-module--margin2_vertical--1gWI4";
export var margin2_top = "contactForm-module--margin2_top--1zNkJ";
export var margin2_bottom = "contactForm-module--margin2_bottom--_xyHX";
export var margin2_left = "contactForm-module--margin2_left--1Ktdh";
export var margin2_right = "contactForm-module--margin2_right--3a0cw";
export var margin3 = "contactForm-module--margin3--1mIM3";
export var margin3_side = "contactForm-module--margin3_side--2OKMe";
export var margin3_vertical = "contactForm-module--margin3_vertical--1Xmu6";
export var margin3_top = "contactForm-module--margin3_top--3m1AI";
export var margin3_bottom = "contactForm-module--margin3_bottom--2B-3r";
export var margin3_left = "contactForm-module--margin3_left--2Q2C3";
export var margin3_right = "contactForm-module--margin3_right--23DIE";
export var navItem = "contactForm-module--navItem--3_c6c";
export var copyright = "contactForm-module--copyright--2-iEi";
export var blogSub = "contactForm-module--blogSub--6REBA";
export var date = "contactForm-module--date--cs3b7";
export var blogCategory = "contactForm-module--blogCategory--p_Vy4";
export var blogDetailMain = "contactForm-module--blogDetailMain--3W-i8";
export var blogDetailSub = "contactForm-module--blogDetailSub--2RDQb";
export var contents = "contactForm-module--contents--2qIni";
export var hljs = "contactForm-module--hljs--3EwuW";
export var thumbnail = "contactForm-module--thumbnail--XgXjI";
export var aboutWrapper = "contactForm-module--aboutWrapper--14y_S";
export var introduction = "contactForm-module--introduction--eLRM2";
export var logo = "contactForm-module--logo--3IpN-";
export var wrapper = "contactForm-module--wrapper--34DeN";
export var input = "contactForm-module--input--2eHQY";
export var shadebutton = "contactForm-module--shadebutton--1UXsR";
export var message = "contactForm-module--message--1BmVU";